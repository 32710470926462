"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var extractNames = require("@CommonFrontendBackend/Utils/ExtractNames");
exports.default = (function (createUi, Input, AsyncButton, $q, utils, environment) {
    var makeSignupForm = function (submit, visibleFields) {
        var signup = function () {
            return $q
                .resolve()
                .then(getSubmitObj)
                .then(function (submitObj) { return validateSubmitObj(submitObj, fields); })
                .then(submit);
        };
        var onChange = function () {
            if (fields.filter(function (field) { return !field.value; }).length === 0 && button.state === "disabled") {
                button.state = "idle";
            }
        };
        var allFields = [
            {
                name: "name",
                onChange: onChange,
            },
            {
                name: "email",
                label: "Work email",
                type: "email",
                onChange: onChange,
            },
            {
                name: "password",
                type: "password",
                onChange: onChange,
            },
        ];
        var fields = allFields
            .filter(function (field) {
            if (visibleFields) {
                return visibleFields.includes(field.name);
            }
            else {
                return true;
            }
        })
            .map(function (field) { return Input(field); });
        var button = new AsyncButton(signup, "Sign up", {
            pointFinger: function (response) {
                var message = getErrorMessage(response);
                var field;
                if (message.includes("email")) {
                    field = getFieldByName(fields, "email");
                }
                else if (message.includes("password")) {
                    field = getFieldByName(fields, "password");
                }
                else if (message.includes("name")) {
                    field = getFieldByName(fields, "name");
                }
                if (field) {
                    return ".input-v2--id-".concat(field.id);
                }
            },
            getErrorMessage: getErrorMessage,
        });
        var getSubmitObj = function () {
            var submitObj = {
                name: null,
            };
            fields.forEach(function (field) {
                submitObj[field.name.toLowerCase()] = field.value;
            });
            return __assign(__assign({}, submitObj), extractNames(submitObj.name));
        };
        return createUi(require("./SignupForm.html"), {
            fields: fields,
            button: button,
            devFill: function () {
                if (environment === "production") {
                    return;
                }
                fields.forEach(function (field) {
                    if (!field.value) {
                        if (field.name === "name") {
                            field.value = "".concat(utils.randomString(6), " ").concat(utils.randomString(8));
                        }
                        else if (field.type === "text" || field.type === "password") {
                            field.value = utils.randomString(10);
                        }
                        else if (field.type === "email") {
                            field.value = "".concat(utils.randomString(10), "@testing.com");
                        }
                    }
                });
                onChange();
            },
        });
    };
    var getErrorMessage = function (response) {
        if (response && response.data && response.data.error && response.data.error.message) {
            return response.data.error.message;
        }
        else if (response && response.data && response.data.message) {
            return response.data.message;
        }
        else if (response && response.message) {
            return response.message;
        }
        else {
            return response;
        }
    };
    var validateSubmitObj = function (submitObj, fields) {
        if (!submitObj.name && getFieldByName(fields, "name")) {
            return $q.reject(new Error("Please enter a name"));
        }
        else if (!submitObj.email && getFieldByName(fields, "email")) {
            return $q.reject(new Error("You've entered an invalid email. Try again?"));
        }
        else if (!submitObj.password && getFieldByName(fields, "password")) {
            return $q.reject(new Error("Please enter a password"));
        }
        else {
            return submitObj;
        }
    };
    var getFieldByName = function (fields, name) {
        return fields.find(function (field) { return field.name === name; });
    };
    return makeSignupForm;
});
