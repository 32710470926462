"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports._oldVideoToNewText = exports._mapVideoContentToTextContent = exports.createNewTextBlockFromVideo = void 0;
var lodash_1 = require("lodash");
var MappingOldToNew_1 = require("./MappingOldToNew");
var Video_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgets/Video");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var Utils_1 = require("./Utils");
var createNewTextBlockFromVideo = function (videoBlock, accountId, brandColors) {
    var isSavedBlock = !!videoBlock.id;
    var newBaseFieldMapping = (0, MappingOldToNew_1.mapBaseFieldsOldToNew)(videoBlock, isSavedBlock, accountId);
    var newTextMapping = (0, exports._oldVideoToNewText)(videoBlock);
    var style = (0, Utils_1.oldTextStylesToNewTextStyles)(videoBlock.style, brandColors);
    return (0, lodash_1.merge)({}, newBaseFieldMapping, newTextMapping, { style: style });
};
exports.createNewTextBlockFromVideo = createNewTextBlockFromVideo;
var mapSizeToWidth = function (size) {
    switch (size) {
        case "small":
            return 75;
        case "medium":
            return "content";
        case "standard":
        default:
            return "wide";
    }
};
var _mapVideoContentToTextContent = function (videoBlock) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var size = (_b = (_a = videoBlock.settings) === null || _a === void 0 ? void 0 : _a.size) !== null && _b !== void 0 ? _b : "standard";
    var isUsingContentTokens = !!videoBlock.contentAreas.header && !!videoBlock.contentAreas.header.tokens;
    if (isUsingContentTokens) {
        var embedUrl = (_d = (_c = videoBlock.embedUrl) !== null && _c !== void 0 ? _c : videoBlock.url) !== null && _d !== void 0 ? _d : "";
        var displayUrl = (_e = videoBlock.url) !== null && _e !== void 0 ? _e : "";
        var videoWidget = (0, Video_1.createVideoToken)({
            embedUrl: embedUrl,
            displayUrl: displayUrl,
            width: mapSizeToWidth(size),
        });
        var existingTokens = (_g = (_f = videoBlock.contentAreas.header) === null || _f === void 0 ? void 0 : _f.tokens) !== null && _g !== void 0 ? _g : [];
        return {
            mainContent: __assign({ tokens: __spreadArray(__spreadArray([], __read(existingTokens), false), [videoWidget], false), content: (_j = (_h = videoBlock.contentAreas.header) === null || _h === void 0 ? void 0 : _h.content) !== null && _j !== void 0 ? _j : "" }, (0, CommonUtils_1.includeIf)(((_k = videoBlock.contentAreas.header) === null || _k === void 0 ? void 0 : _k.widgets) !== undefined, {
                widgets: (_l = videoBlock.contentAreas.header) === null || _l === void 0 ? void 0 : _l.widgets,
            })),
        };
    }
    else {
        var isWistia = videoBlock.embedUrl && videoBlock.embedUrl.includes("fast.wistia.net");
        var iframeClassNames = "legacy-video-widget legacy-video-widget--".concat(size).concat(isWistia ? " wistia_embed" : "");
        var iframeString = "\n      <div class=\"legacy-video-widget-container legacy-video-widget-container--".concat(size, "\">\n        <iframe \n          src=\"").concat(videoBlock.embedUrl, "\" \n          class=\"").concat(iframeClassNames, "\"\n          data-qwilr-widget-type=\"video\" \n          data-qwilr-display-url=\"").concat(videoBlock.url, "\" \n          data-qwilr-widget-width=\"").concat(size, "\" \n          data-qwilr-embed-url=\"").concat(videoBlock.embedUrl, "\" \n          ").concat(isWistia ? "name=\"wistia_embed\"" : "", "\n          frameborder=\"0\"\n          allowfullscreen\n        >\n        </iframe>\n      </div>");
        var existingContent = (_o = (_m = videoBlock.contentAreas.header) === null || _m === void 0 ? void 0 : _m.content) !== null && _o !== void 0 ? _o : "";
        return {
            mainContent: {
                widgets: (_p = videoBlock.contentAreas.header) === null || _p === void 0 ? void 0 : _p.widgets,
                content: existingContent + iframeString,
            },
        };
    }
};
exports._mapVideoContentToTextContent = _mapVideoContentToTextContent;
var _oldVideoToNewText = function (oldBlock) {
    return {
        settings: (0, Utils_1.mapSettings)(oldBlock.settings),
        contentAreas: (0, exports._mapVideoContentToTextContent)(oldBlock),
    };
};
exports._oldVideoToNewText = _oldVideoToNewText;
