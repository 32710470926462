"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports._createTextContent = exports._oldEmbedToNewText = exports.oldEmbedStylesToNewTextStyles = exports.createNewTextBlockFromEmbedBlock = void 0;
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var lodash_1 = require("lodash");
var MappingOldToNew_1 = require("./MappingOldToNew");
var BlockStyleTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/BlockStyleTypes");
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var defaultStyles_1 = require("@CommonFrontend/Styles/BlockStyles/defaultStyles");
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
var IFrame_1 = require("WidgetRegistry/CommonWidgets/IFrame");
var Utils_1 = require("@CommonFrontendBackend/BlockConsolidation/Utils");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var createNewTextBlockFromEmbedBlock = function (embedBlock, accountId, brandColors) {
    var isSavedBlock = !!embedBlock.id;
    var newBaseFieldMapping = (0, MappingOldToNew_1.mapBaseFieldsOldToNew)(embedBlock, isSavedBlock, accountId);
    var newTextMapping = (0, exports._oldEmbedToNewText)(embedBlock);
    var style = (0, exports.oldEmbedStylesToNewTextStyles)(embedBlock.style, brandColors);
    return (0, lodash_1.merge)({}, newBaseFieldMapping, newTextMapping, { style: style });
};
exports.createNewTextBlockFromEmbedBlock = createNewTextBlockFromEmbedBlock;
var oldEmbedStylesToNewTextStyles = function (style, brandColors) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var primary = (0, GetBlockStyle_1.convertColorToColorObj)(brandColors[0]);
    var secondary = (0, GetBlockStyle_1.convertColorToColorObj)(brandColors[1]);
    var defaultMidnight = (0, Utils_1.convertColorObjToIdealColorSchema)(DefaultColors_1.DEFAULT_MIDNIGHT);
    var defaultWhite = (0, Utils_1.convertColorObjToIdealColorSchema)(DefaultColors_1.DEFAULT_WHITE);
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ isMigrated: true, migratedAt: new Date().toISOString() }, (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style._id) !== undefined, { _id: style === null || style === void 0 ? void 0 : style._id })), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.account) !== undefined, { account: style === null || style === void 0 ? void 0 : style.account })), { background: {
            abs: (_b = (_a = style === null || style === void 0 ? void 0 : style.background) === null || _a === void 0 ? void 0 : _a.abs) !== null && _b !== void 0 ? _b : DefaultColors_1.DEFAULT_WHITE.value,
            themeIndex: ((_c = style === null || style === void 0 ? void 0 : style.background) === null || _c === void 0 ? void 0 : _c.themeIndex) !== undefined ? (_d = style === null || style === void 0 ? void 0 : style.background) === null || _d === void 0 ? void 0 : _d.themeIndex : DefaultColors_1.DEFAULT_WHITE.themeIndex,
            opacity: (_f = (_e = style === null || style === void 0 ? void 0 : style.background) === null || _e === void 0 ? void 0 : _e.opacity) !== null && _f !== void 0 ? _f : 1,
            tintBlendMode: commonTypes_1.TintBlendModeTypes.Normal,
            card: { enabled: false, color: defaultStyles_1.defaultRgbaColor },
            type: "color",
            tintColor: defaultStyles_1.defaultRgbaColor,
        }, name: (_g = style === null || style === void 0 ? void 0 : style.name) !== null && _g !== void 0 ? _g : "", blockType: commonTypes_1.BlockType.Text, hash: (_h = style === null || style === void 0 ? void 0 : style.hash) !== null && _h !== void 0 ? _h : "" }), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.clonedFrom) !== undefined, { clonedFrom: style === null || style === void 0 ? void 0 : style.clonedFrom })), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.preTcdcRef) !== undefined, { preTcdcRef: style === null || style === void 0 ? void 0 : style.preTcdcRef })), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.isFactoryStyle) !== undefined, { isFactoryStyle: style === null || style === void 0 ? void 0 : style.isFactoryStyle })), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.css) !== undefined, { css: style === null || style === void 0 ? void 0 : style.css })), { blockSpacing: (_j = style === null || style === void 0 ? void 0 : style.blockSpacing) !== null && _j !== void 0 ? _j : commonTypes_1.OptionLabelTypes.Medium, blockWidth: (_k = style === null || style === void 0 ? void 0 : style.blockWidth) !== null && _k !== void 0 ? _k : commonTypes_1.OptionLabelTypes.Medium, blockHeight: "none", h1: {
            color: primary,
            alignment: commonTypes_1.AlignmentTypes.Left,
            size: { abs: 65, themeRef: "medium" },
        }, h2: {
            color: secondary,
            alignment: commonTypes_1.AlignmentTypes.Left,
            size: { abs: 40, themeRef: "medium" },
        }, p: {
            color: defaultMidnight,
            alignment: commonTypes_1.AlignmentTypes.Left,
            size: { abs: 20, themeRef: "medium" },
        }, animation: {
            animationOption: (_m = (_l = style === null || style === void 0 ? void 0 : style.animation) === null || _l === void 0 ? void 0 : _l.animationOption) !== null && _m !== void 0 ? _m : commonTypes_1.AnimationOption.None,
            animationType: (_p = (_o = style === null || style === void 0 ? void 0 : style.animation) === null || _o === void 0 ? void 0 : _o.animationType) !== null && _p !== void 0 ? _p : commonTypes_1.AnimationType.Fade,
            direction: (_r = (_q = style === null || style === void 0 ? void 0 : style.animation) === null || _q === void 0 ? void 0 : _q.direction) !== null && _r !== void 0 ? _r : commonTypes_1.AnimationDirection.Down,
            speed: (_t = (_s = style === null || style === void 0 ? void 0 : style.animation) === null || _s === void 0 ? void 0 : _s.speed) !== null && _t !== void 0 ? _t : commonTypes_1.AnimationSpeed.Medium,
        }, blockQuote: {
            color: defaultMidnight,
            size: {
                abs: 27,
                themeRef: "medium",
            },
            barColor: primary,
            displayQuoteIcon: false,
        }, button: {
            textColor: "dark",
            roundness: 4,
            size: BlockStyleTypes_1.ButtonWidgetSize.Medium,
            color: primary,
        }, accordion: {
            headerBackground: {
                color: defaultWhite,
                on: true,
            },
            bodyBackground: { on: false },
            buttonAlignment: BlockStyleTypes_1.AccordionWidgetButtonAlignment.Left,
            buttonColor: primary,
            buttonStyle: BlockStyleTypes_1.AccordionWidgetButtonStyle.Arrow,
            cardStyle: BlockStyleTypes_1.AccordionWidgetCardStyle.Simple,
            imageHeight: BlockStyleTypes_1.AccordionWidgetImageHeight.Medium,
            tint: {
                color: defaultMidnight,
                opacity: 25,
            },
            expandButtonText: "Expand",
            closeButtonText: "Collapse",
        }, link: {
            color: defaultMidnight,
            underline: {
                color: defaultMidnight,
                on: true,
            },
        }, list: {
            color: defaultMidnight,
            marker: commonTypes_1.ListMarkerType.Circle,
            markerColor: defaultMidnight,
        }, roiCalculator: {
            highlightColor: primary,
            textColor: "dark",
            background: {
                color: defaultWhite,
                imageBlur: 0,
            },
            tint: {
                color: defaultMidnight,
                opacity: 40,
            },
        }, table: {
            background: {
                color: defaultWhite,
                on: false,
            },
            border: {
                color: {
                    themeIndex: DefaultColors_1.DEFAULT_GREY.themeIndex,
                    abs: (0, ColorUtils_1.replaceOpacity)(DefaultColors_1.DEFAULT_GREY.value, 0.25),
                    opacity: 0.25,
                },
                borderType: commonTypes_1.TableBorderType.All,
            },
            header: {
                color: defaultWhite,
                on: false,
            },
        } });
};
exports.oldEmbedStylesToNewTextStyles = oldEmbedStylesToNewTextStyles;
var _oldEmbedToNewText = function (oldBlock) {
    return {
        settings: (0, Utils_1.mapSettings)(oldBlock.settings),
        contentAreas: (0, exports._createTextContent)(oldBlock),
    };
};
exports._oldEmbedToNewText = _oldEmbedToNewText;
var mapSourceTypeToEmbedPreset = function (sourceType) {
    if (!sourceType) {
        return "iframe";
    }
    if (["infogram", "paperform", "prezi", "calendly", "slideshare", "typeform"].includes(sourceType)) {
        return sourceType;
    }
    switch (sourceType) {
        case "microsoftWord":
            return "microsoft-word";
        case "microsoftExcel":
            return "microsoft-excel";
        case "microsoftPowerpoint":
            return "powerpoint";
        case "googleSheets":
            return "google-sheets";
        case "googleForms":
            return "google-forms";
        case "iframeEmbed":
        case "twentyThree":
        case "googleMaps":
        case "invision":
        default:
            return "iframe";
    }
};
var _createTextContent = function (oldBlock) {
    var embedUrl = oldBlock.url || "";
    var width = "content";
    var height = 70;
    var embedWidget = (0, IFrame_1.createEmbedToken)({ embedUrl: embedUrl, width: width, height: height });
    return {
        mainContent: {
            widgets: {},
            content: "",
            tokens: [__assign(__assign({}, embedWidget), { alignment: "center", embedPreset: mapSourceTypeToEmbedPreset(oldBlock.sourceType) })],
        },
    };
};
exports._createTextContent = _createTextContent;
