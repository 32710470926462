"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compileBlockStyleVars = void 0;
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var dynamic_1 = require("@vanilla-extract/dynamic");
var BlockStyles_css_1 = require("Styles/BlockStyles/BlockStyles.css");
var BlockStyleTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/BlockStyleTypes");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var lodash_1 = require("lodash");
var defaultStyles_1 = require("./defaultStyles");
var compileBlockStyleVars = function (block, colors) {
    var _a, _b, _c, _d, _e;
    var _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    var styleVars = {};
    var getColorFn = (0, GetBlockStyle_1.GetColor)(colors);
    if (hasStyle(block.style)) {
        if (block.style.background) {
            var backgroundColor = getColorFn(block.style.background);
            Object.assign(styleVars, (_a = {},
                _a[BlockStyles_css_1.blockStyleVars.backgroundColor] = backgroundColor,
                _a));
        }
        if ((0, BlockStyleTypes_1.isTextStyle)(block.style)) {
            if (block.style.background.url && block.style.background.type === "image") {
                Object.assign(styleVars, (_b = {},
                    _b[BlockStyles_css_1.blockStyleVars.backgroundImage] = "url(".concat(block.style.background.url, ")"),
                    _b));
            }
            if (block.style.background.videoThumbnail && block.style.background.type === "video") {
                Object.assign(styleVars, (_c = {},
                    _c[BlockStyles_css_1.blockStyleVars.backgroundVideoThumbnail] = "url(".concat(block.style.background.videoThumbnail, ")"),
                    _c));
            }
            var withBackgroundAsset = block.style.background.type === "image" || block.style.background.type === "video";
            if (withBackgroundAsset) {
                var tintColor = {
                    abs: (_j = (_h = (_g = (_f = block.style) === null || _f === void 0 ? void 0 : _f.background) === null || _g === void 0 ? void 0 : _g.tintColor) === null || _h === void 0 ? void 0 : _h.abs) !== null && _j !== void 0 ? _j : defaultStyles_1.defaultRgbaColor.abs,
                    themeIndex: (_o = (_m = (_l = (_k = block.style) === null || _k === void 0 ? void 0 : _k.background) === null || _l === void 0 ? void 0 : _l.tintColor) === null || _m === void 0 ? void 0 : _m.themeIndex) !== null && _o !== void 0 ? _o : defaultStyles_1.defaultRgbaColor.themeIndex,
                    opacity: (_s = (_r = (_q = (_p = block.style) === null || _p === void 0 ? void 0 : _p.background) === null || _q === void 0 ? void 0 : _q.tintColor) === null || _r === void 0 ? void 0 : _r.opacity) !== null && _s !== void 0 ? _s : defaultStyles_1.defaultRgbaColor.opacity,
                };
                Object.assign(styleVars, (_d = {},
                    _d[BlockStyles_css_1.blockStyleVars.backgroundTintColor] = getColorFn(tintColor),
                    _d[BlockStyles_css_1.blockStyleVars.backgroundTintBlendMode] = ((_t = block.style) === null || _t === void 0 ? void 0 : _t.background.tintBlendMode) === commonTypes_1.TintBlendModeTypes.Blend ? "multiply" : "normal",
                    _d[BlockStyles_css_1.blockStyleVars.backgroundCardColor] = ((_u = block.style.background.card) === null || _u === void 0 ? void 0 : _u.enabled)
                        ? getColorFn((_w = (_v = block.style.background.card) === null || _v === void 0 ? void 0 : _v.color) !== null && _w !== void 0 ? _w : defaultStyles_1.defaultRgbaColor)
                        : "transparent",
                    _d));
            }
            Object.assign(styleVars, (_e = {},
                _e[BlockStyles_css_1.blockStyleVars.minBlockHeight] = blockHeight((_y = (_x = block.style) === null || _x === void 0 ? void 0 : _x.blockHeight) !== null && _y !== void 0 ? _y : "none"),
                _e));
        }
    }
    return (0, dynamic_1.assignInlineVars)(styleVars);
};
exports.compileBlockStyleVars = compileBlockStyleVars;
var blockHeight = function (setting) {
    switch (setting) {
        case "small": {
            return "33vh";
        }
        case "medium": {
            return "65vh";
        }
        case "large": {
            return "100vh";
        }
        default: {
            return "auto";
        }
    }
};
var hasStyle = function (style) {
    return !!style && !(0, lodash_1.isEmpty)(style);
};
