"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSchemaForStyleType = exports.migrateStyle = exports.getSchemaForBlockType = exports.migrateBlock = exports.mapBaseFieldsOldToNew = void 0;
var MappingBlockZodSchemas_1 = require("@CommonFrontendBackend/BlockConsolidation/MappingBlockZodSchemas");
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var SplashMapping_1 = require("./SplashMapping");
var VideoMapping_1 = require("./VideoMapping");
var TextMapping_1 = require("./TextMapping");
var EmbedMapping_1 = require("./EmbedMapping");
var Utils_1 = require("./Utils");
var mapBaseFieldsOldToNew = function (oldBlock, isSavedBlock, accountId) {
    var _a, _b;
    return __assign(__assign(__assign(__assign(__assign(__assign({ name: oldBlock.name || "", _id: oldBlock._id, cssBlockName: "text-block", projectId: oldBlock.projectId, createdBy: oldBlock.createdBy, createdAt: oldBlock.createdAt, persistVersion: oldBlock.persistVersion, isAccepted: (_a = oldBlock.isAccepted) !== null && _a !== void 0 ? _a : false }, (0, CommonUtils_1.includeIf)(oldBlock.__v !== undefined, { __v: oldBlock.__v })), { lock: oldBlock.lock, type: BlockTypes_1.BlockType.Text, displayType: oldBlock.type, isMigrated: true, migratedAt: new Date().toISOString(), lastEditedAt: (_b = oldBlock.lastEditedAt) !== null && _b !== void 0 ? _b : oldBlock.createdAt, account: accountId }), (0, CommonUtils_1.includeIf)(oldBlock.blockPosition !== undefined, { blockPosition: oldBlock.blockPosition })), (0, CommonUtils_1.includeIf)(!!oldBlock.clonedFrom, { clonedFrom: oldBlock.clonedFrom })), (0, CommonUtils_1.includeIf)(!!oldBlock.fromSavedBlock, { fromSavedBlock: oldBlock.fromSavedBlock })), (0, CommonUtils_1.includeIf)(isSavedBlock, {
        savedBlockName: oldBlock.savedBlockName,
        id: oldBlock.id,
        lastUsedAt: oldBlock.lastUsedAt,
        parentFolder: oldBlock.parentFolder,
    }));
};
exports.mapBaseFieldsOldToNew = mapBaseFieldsOldToNew;
var migrateBlock = function (block, accountId, brandColors) {
    switch (block.type) {
        case BlockTypes_1.BlockType.Splash: {
            return (0, SplashMapping_1.createNewTextBlockFromSplash)(block, accountId, brandColors);
        }
        case BlockTypes_1.BlockType.Video: {
            return (0, VideoMapping_1.createNewTextBlockFromVideo)(block, accountId, brandColors);
        }
        case BlockTypes_1.BlockType.Text: {
            return (0, TextMapping_1.createNewTextBlockFromTextBlock)(block, accountId, brandColors);
        }
        case BlockTypes_1.BlockType.Source: {
            return (0, EmbedMapping_1.createNewTextBlockFromEmbedBlock)(block, accountId, brandColors);
        }
        default: {
            throw new Error("Attempted to migrate unsupported block type");
        }
    }
};
exports.migrateBlock = migrateBlock;
var getSchemaForBlockType = function (type) {
    switch (type) {
        case BlockTypes_1.BlockType.Text: {
            return MappingBlockZodSchemas_1.textBlockValidationSchema;
        }
        case BlockTypes_1.BlockType.Source: {
            return MappingBlockZodSchemas_1.embedBlockValidationSchema;
        }
        case BlockTypes_1.BlockType.Splash: {
            return MappingBlockZodSchemas_1.splashBlockValidationSchema;
        }
        case BlockTypes_1.BlockType.Video: {
            return MappingBlockZodSchemas_1.videoBlockValidationSchema;
        }
        default: {
            throw new Error("Unsupported block type");
        }
    }
};
exports.getSchemaForBlockType = getSchemaForBlockType;
var migrateStyle = function (style, brandColors) {
    if (isOldTextOrVideoStyle(style)) {
        return (0, Utils_1.oldTextStylesToNewTextStyles)(style, brandColors);
    }
    switch (style.blockType) {
        case BlockTypes_1.BlockType.Splash: {
            return (0, SplashMapping_1.oldSplashStylesToNewTextStyles)(style, brandColors);
        }
        case BlockTypes_1.BlockType.Source: {
            return (0, EmbedMapping_1.oldEmbedStylesToNewTextStyles)(style, brandColors);
        }
        default: {
            throw new Error("Attempted to migrate unsupported styles type");
        }
    }
};
exports.migrateStyle = migrateStyle;
var isOldTextOrVideoStyle = function (style) {
    if (!style) {
        return false;
    }
    return style.blockType === BlockTypes_1.BlockType.Text;
};
var getSchemaForStyleType = function (type) {
    switch (type) {
        case BlockTypes_1.BlockType.Text: {
            return MappingBlockZodSchemas_1.sharedBlockStylesBasedOnText;
        }
        case BlockTypes_1.BlockType.Source: {
            return MappingBlockZodSchemas_1.embedBlockStylesSchema;
        }
        case BlockTypes_1.BlockType.Splash: {
            return MappingBlockZodSchemas_1.splashBlockStylesSchema;
        }
        default: {
            throw new Error("Unsupported style type");
        }
    }
};
exports.getSchemaForStyleType = getSchemaForStyleType;
