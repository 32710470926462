"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.oldSplashStylesToNewTextStyles = exports._oldSplashToNewText = exports.createNewTextBlockFromSplash = void 0;
var lodash_1 = require("lodash");
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var BlockStyleTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/BlockStyleTypes");
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var Utils_1 = require("./Utils");
var MappingOldToNew_1 = require("./MappingOldToNew");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var defaultStyles_1 = require("Styles/BlockStyles/defaultStyles");
var createNewTextBlockFromSplash = function (oldSplashBlock, accountId, brandColors) {
    var isSavedBlock = !!oldSplashBlock.id;
    var newBaseFieldMapping = (0, MappingOldToNew_1.mapBaseFieldsOldToNew)(oldSplashBlock, isSavedBlock, accountId);
    var newTextMapping = (0, exports._oldSplashToNewText)(oldSplashBlock);
    var style = (0, exports.oldSplashStylesToNewTextStyles)(oldSplashBlock.style, brandColors, {
        resourceUrl: oldSplashBlock.resourceUrl,
        resourceType: oldSplashBlock.resourceType,
        imageUrl: oldSplashBlock.imageUrl,
    });
    return (0, lodash_1.merge)(newBaseFieldMapping, newTextMapping, { style: style });
};
exports.createNewTextBlockFromSplash = createNewTextBlockFromSplash;
var _oldSplashToNewText = function (splashBlock) {
    return __assign({ settings: (0, Utils_1.mapSettings)(splashBlock.settings), contentAreas: __assign({ mainContent: splashBlock.contentAreas.main }, (0, CommonUtils_1.includeIf)(splashBlock.contentAreas.widgets !== undefined, { widgets: splashBlock.contentAreas.widgets })) }, (0, CommonUtils_1.includeIf)(splashBlock.moduleName !== undefined, { moduleName: splashBlock.moduleName }));
};
exports._oldSplashToNewText = _oldSplashToNewText;
var resourceTypeToBackgroundTypeMap = function (type) {
    switch (type) {
        case "image":
            return "image";
        case "video":
            return "video";
        case "embed":
            return "video";
        default:
            return "color";
    }
};
var createVideoThumbnailUrl = function (resourceUrl, resourceType) {
    if (!resourceUrl || resourceType === "image") {
        return;
    }
    if (resourceType === "video") {
        var fileUrl = resourceUrl.split(".").slice(0, -1).join(".");
        return "".concat(fileUrl, ".jpg");
    }
    if (resourceType === "embed" && (0, Utils_1.isYoutubeLink)(resourceUrl)) {
        var id = (0, Utils_1.getYoutubeId)(resourceUrl);
        if (id) {
            return "https://img.youtube.com/vi/".concat(id, "/maxresdefault.jpg");
        }
    }
    return;
};
var oldSplashStylesToNewTextStyles = function (style, brandColors, background) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84;
    var primary = (0, GetBlockStyle_1.convertColorToColorObj)(brandColors[0]);
    var secondary = (0, GetBlockStyle_1.convertColorToColorObj)(brandColors[1]);
    var defaultMidnight = (0, Utils_1.convertColorObjToIdealColorSchema)(DefaultColors_1.DEFAULT_MIDNIGHT);
    var defaultWhite = (0, Utils_1.convertColorObjToIdealColorSchema)(DefaultColors_1.DEFAULT_WHITE);
    var tableBackground = (style === null || style === void 0 ? void 0 : style.tableBackground)
        ? (0, lodash_1.cloneDeep)(style.tableBackground)
        : {
            on: false,
            color: defaultWhite,
        };
    var tableHeader = (style === null || style === void 0 ? void 0 : style.tableHeader)
        ? (0, lodash_1.cloneDeep)(style.tableHeader)
        : {
            on: false,
            color: defaultWhite,
        };
    var tableBorder = (style === null || style === void 0 ? void 0 : style.tableBorder)
        ? (0, lodash_1.cloneDeep)(style === null || style === void 0 ? void 0 : style.tableBorder)
        : {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_GREY.themeIndex,
                abs: (0, ColorUtils_1.replaceOpacity)(DefaultColors_1.DEFAULT_GREY.value, 0.25),
                opacity: 0.25,
            },
            borderType: commonTypes_1.TableBorderType.All,
        };
    var defaultPOrMidnight = !!((_b = (_a = style === null || style === void 0 ? void 0 : style.p) === null || _a === void 0 ? void 0 : _a.color) === null || _b === void 0 ? void 0 : _b.abs) ? style.p.color : defaultMidnight;
    var resourceUrl = (_c = background === null || background === void 0 ? void 0 : background.resourceUrl) !== null && _c !== void 0 ? _c : background === null || background === void 0 ? void 0 : background.imageUrl;
    var mappedResourceType = resourceTypeToBackgroundTypeMap(background === null || background === void 0 ? void 0 : background.resourceType);
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ isMigrated: true, migratedAt: new Date().toISOString() }, (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style._id) !== undefined, { _id: style === null || style === void 0 ? void 0 : style._id })), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.account) !== undefined, { account: style === null || style === void 0 ? void 0 : style.account })), { name: (style === null || style === void 0 ? void 0 : style.name) || "", blockType: BlockTypes_1.BlockType.Text, blockHeight: (style === null || style === void 0 ? void 0 : style.blockSpacing) || "none", blockSpacing: "medium" }), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.preTcdcRef) !== undefined, { preTcdcRef: style === null || style === void 0 ? void 0 : style.preTcdcRef })), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.isFactoryStyle) !== undefined, {
        isFactoryStyle: style === null || style === void 0 ? void 0 : style.isFactoryStyle,
    })), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.css) !== undefined, { css: style === null || style === void 0 ? void 0 : style.css })), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.alignment) !== undefined, { alignment: style === null || style === void 0 ? void 0 : style.alignment })), { h1: {
            color: (0, Utils_1.makeColor)(primary, (_d = style === null || style === void 0 ? void 0 : style.h1) === null || _d === void 0 ? void 0 : _d.color),
            size: {
                abs: (_g = (_f = (_e = style === null || style === void 0 ? void 0 : style.h1) === null || _e === void 0 ? void 0 : _e.size) === null || _f === void 0 ? void 0 : _f.abs) !== null && _g !== void 0 ? _g : 65,
                themeRef: (_k = (_j = (_h = style === null || style === void 0 ? void 0 : style.h1) === null || _h === void 0 ? void 0 : _h.size) === null || _j === void 0 ? void 0 : _j.themeRef) !== null && _k !== void 0 ? _k : "medium",
            },
            alignment: ((_l = style === null || style === void 0 ? void 0 : style.h1) === null || _l === void 0 ? void 0 : _l.alignment) || commonTypes_1.AlignmentTypes.Left,
        }, h2: {
            color: (0, Utils_1.makeColor)(secondary, (_m = style === null || style === void 0 ? void 0 : style.h2) === null || _m === void 0 ? void 0 : _m.color),
            size: {
                abs: (_q = (_p = (_o = style === null || style === void 0 ? void 0 : style.h2) === null || _o === void 0 ? void 0 : _o.size) === null || _p === void 0 ? void 0 : _p.abs) !== null && _q !== void 0 ? _q : 40,
                themeRef: (_t = (_s = (_r = style === null || style === void 0 ? void 0 : style.h2) === null || _r === void 0 ? void 0 : _r.size) === null || _s === void 0 ? void 0 : _s.themeRef) !== null && _t !== void 0 ? _t : "medium",
            },
            alignment: ((_u = style === null || style === void 0 ? void 0 : style.h2) === null || _u === void 0 ? void 0 : _u.alignment) || commonTypes_1.AlignmentTypes.Left,
        }, p: {
            color: (0, Utils_1.makeColor)(defaultMidnight, (_v = style === null || style === void 0 ? void 0 : style.p) === null || _v === void 0 ? void 0 : _v.color),
            size: {
                abs: (_y = (_x = (_w = style === null || style === void 0 ? void 0 : style.p) === null || _w === void 0 ? void 0 : _w.size) === null || _x === void 0 ? void 0 : _x.abs) !== null && _y !== void 0 ? _y : 20,
                themeRef: (_1 = (_0 = (_z = style === null || style === void 0 ? void 0 : style.p) === null || _z === void 0 ? void 0 : _z.size) === null || _0 === void 0 ? void 0 : _0.themeRef) !== null && _1 !== void 0 ? _1 : "medium",
            },
            alignment: ((_2 = style === null || style === void 0 ? void 0 : style.p) === null || _2 === void 0 ? void 0 : _2.alignment) || commonTypes_1.AlignmentTypes.Left,
        }, blockQuote: __assign({ color: (0, Utils_1.makeColor)(defaultPOrMidnight, (_3 = style === null || style === void 0 ? void 0 : style.blockQuote) === null || _3 === void 0 ? void 0 : _3.color), size: {
                abs: (_6 = (_5 = (_4 = style === null || style === void 0 ? void 0 : style.blockQuote) === null || _4 === void 0 ? void 0 : _4.size) === null || _5 === void 0 ? void 0 : _5.abs) !== null && _6 !== void 0 ? _6 : 27,
                themeRef: (_9 = (_8 = (_7 = style === null || style === void 0 ? void 0 : style.blockQuote) === null || _7 === void 0 ? void 0 : _7.size) === null || _8 === void 0 ? void 0 : _8.themeRef) !== null && _9 !== void 0 ? _9 : "medium",
            }, barColor: (0, Utils_1.makeColor)(primary, (_10 = style === null || style === void 0 ? void 0 : style.blockQuote) === null || _10 === void 0 ? void 0 : _10.barColor), displayQuoteIcon: (_12 = (_11 = style === null || style === void 0 ? void 0 : style.blockQuote) === null || _11 === void 0 ? void 0 : _11.displayQuoteIcon) !== null && _12 !== void 0 ? _12 : false }, (0, CommonUtils_1.includeIf)(((_13 = style === null || style === void 0 ? void 0 : style.blockQuote) === null || _13 === void 0 ? void 0 : _13.alignment) !== undefined, {
            alignment: (_14 = style === null || style === void 0 ? void 0 : style.blockQuote) === null || _14 === void 0 ? void 0 : _14.alignment,
        })), background: __assign(__assign({ abs: DefaultColors_1.DEFAULT_WHITE.value, themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex, opacity: 1, tintColor: (0, Utils_1.makeColor)(defaultStyles_1.defaultRgbaColor, style === null || style === void 0 ? void 0 : style.background), tintBlendMode: (style === null || style === void 0 ? void 0 : style.tintBlendMode) || commonTypes_1.TintBlendModeTypes.Normal, card: {
                enabled: (_16 = (_15 = style === null || style === void 0 ? void 0 : style.backgroundCard) === null || _15 === void 0 ? void 0 : _15.on) !== null && _16 !== void 0 ? _16 : false,
                color: ((_17 = style === null || style === void 0 ? void 0 : style.backgroundCard) === null || _17 === void 0 ? void 0 : _17.color) || {
                    themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                    abs: (0, ColorUtils_1.replaceOpacity)(DefaultColors_1.DEFAULT_MIDNIGHT.value, 0.25),
                    opacity: 0.25,
                },
            }, type: mappedResourceType }, (0, CommonUtils_1.includeIf)(!!resourceUrl, {
            url: resourceUrl,
        })), (0, CommonUtils_1.includeIf)(resourceUrl !== undefined && mappedResourceType === "video", {
            videoThumbnail: createVideoThumbnailUrl(resourceUrl, background === null || background === void 0 ? void 0 : background.resourceType),
        })), blockWidth: (_18 = style === null || style === void 0 ? void 0 : style.blockWidth) !== null && _18 !== void 0 ? _18 : commonTypes_1.OptionLabelTypes.Medium, hash: (style === null || style === void 0 ? void 0 : style.hash) || "", animation: {
            animationOption: ((_19 = style === null || style === void 0 ? void 0 : style.animation) === null || _19 === void 0 ? void 0 : _19.animationOption) || commonTypes_1.AnimationOption.None,
            animationType: ((_20 = style === null || style === void 0 ? void 0 : style.animation) === null || _20 === void 0 ? void 0 : _20.animationType) || commonTypes_1.AnimationType.Fade,
            direction: ((_21 = style === null || style === void 0 ? void 0 : style.animation) === null || _21 === void 0 ? void 0 : _21.direction) || commonTypes_1.AnimationDirection.Down,
            speed: ((_22 = style === null || style === void 0 ? void 0 : style.animation) === null || _22 === void 0 ? void 0 : _22.speed) || commonTypes_1.AnimationSpeed.Medium,
        }, table: {
            background: {
                on: (_24 = (_23 = style === null || style === void 0 ? void 0 : style.table) === null || _23 === void 0 ? void 0 : _23.background.on) !== null && _24 !== void 0 ? _24 : tableBackground.on,
                color: (0, Utils_1.makeColor)(tableBackground.color, (_25 = style === null || style === void 0 ? void 0 : style.table) === null || _25 === void 0 ? void 0 : _25.background.color),
            },
            header: {
                on: (_27 = (_26 = style === null || style === void 0 ? void 0 : style.table) === null || _26 === void 0 ? void 0 : _26.header.on) !== null && _27 !== void 0 ? _27 : tableHeader.on,
                color: (0, Utils_1.makeColor)(tableHeader.color, (_28 = style === null || style === void 0 ? void 0 : style.table) === null || _28 === void 0 ? void 0 : _28.header.color),
            },
            border: {
                borderType: ((_29 = style === null || style === void 0 ? void 0 : style.table) === null || _29 === void 0 ? void 0 : _29.border.borderType) || tableBorder.borderType,
                color: (0, Utils_1.makeColor)(tableBorder.color, (_30 = style === null || style === void 0 ? void 0 : style.table) === null || _30 === void 0 ? void 0 : _30.border.color),
            },
        }, list: {
            color: (0, Utils_1.makeColor)(defaultPOrMidnight, (_31 = style === null || style === void 0 ? void 0 : style.list) === null || _31 === void 0 ? void 0 : _31.color),
            marker: (_33 = (_32 = style === null || style === void 0 ? void 0 : style.list) === null || _32 === void 0 ? void 0 : _32.marker) !== null && _33 !== void 0 ? _33 : commonTypes_1.ListMarkerType.Circle,
            markerColor: (0, Utils_1.makeColor)(defaultPOrMidnight, (_34 = style === null || style === void 0 ? void 0 : style.list) === null || _34 === void 0 ? void 0 : _34.color),
        }, link: {
            color: (0, Utils_1.makeColor)(defaultPOrMidnight, (_35 = style === null || style === void 0 ? void 0 : style.link) === null || _35 === void 0 ? void 0 : _35.color),
            underline: {
                on: (_38 = (_37 = (_36 = style === null || style === void 0 ? void 0 : style.link) === null || _36 === void 0 ? void 0 : _36.underline) === null || _37 === void 0 ? void 0 : _37.on) !== null && _38 !== void 0 ? _38 : true,
                color: (0, Utils_1.makeColor)(defaultPOrMidnight, (_40 = (_39 = style === null || style === void 0 ? void 0 : style.link) === null || _39 === void 0 ? void 0 : _39.underline) === null || _40 === void 0 ? void 0 : _40.color),
            },
        }, button: {
            color: (0, Utils_1.makeColor)(primary, (_41 = style === null || style === void 0 ? void 0 : style.button) === null || _41 === void 0 ? void 0 : _41.color),
            roundness: (_43 = (_42 = style === null || style === void 0 ? void 0 : style.button) === null || _42 === void 0 ? void 0 : _42.roundness) !== null && _43 !== void 0 ? _43 : 4,
            size: (_45 = (_44 = style === null || style === void 0 ? void 0 : style.button) === null || _44 === void 0 ? void 0 : _44.size) !== null && _45 !== void 0 ? _45 : BlockStyleTypes_1.ButtonWidgetSize.Medium,
            textColor: ((_47 = (_46 = style === null || style === void 0 ? void 0 : style.button) === null || _46 === void 0 ? void 0 : _46.textColor) !== null && _47 !== void 0 ? _47 : (0, ColorUtils_1.shouldUseDarkText)(primary.abs)) ? "dark" : "light",
        }, roiCalculator: {
            highlightColor: (0, Utils_1.makeColor)(primary, (_48 = style === null || style === void 0 ? void 0 : style.roiCalculator) === null || _48 === void 0 ? void 0 : _48.highlightColor),
            textColor: (_50 = (_49 = style === null || style === void 0 ? void 0 : style.roiCalculator) === null || _49 === void 0 ? void 0 : _49.textColor) !== null && _50 !== void 0 ? _50 : "dark",
            background: {
                color: (0, Utils_1.makeColor)(defaultWhite, (_52 = (_51 = style === null || style === void 0 ? void 0 : style.roiCalculator) === null || _51 === void 0 ? void 0 : _51.background) === null || _52 === void 0 ? void 0 : _52.color),
                imageBlur: (_55 = (_54 = (_53 = style === null || style === void 0 ? void 0 : style.roiCalculator) === null || _53 === void 0 ? void 0 : _53.background) === null || _54 === void 0 ? void 0 : _54.imageBlur) !== null && _55 !== void 0 ? _55 : 0,
            },
            tint: {
                color: (0, Utils_1.makeColor)(defaultMidnight, (_57 = (_56 = style === null || style === void 0 ? void 0 : style.roiCalculator) === null || _56 === void 0 ? void 0 : _56.tint) === null || _57 === void 0 ? void 0 : _57.color),
                opacity: (_60 = (_59 = (_58 = style === null || style === void 0 ? void 0 : style.roiCalculator) === null || _58 === void 0 ? void 0 : _58.tint) === null || _59 === void 0 ? void 0 : _59.opacity) !== null && _60 !== void 0 ? _60 : 40,
            },
        }, accordion: {
            headerBackground: {
                color: (0, Utils_1.makeColor)(defaultWhite, (_62 = (_61 = style === null || style === void 0 ? void 0 : style.accordion) === null || _61 === void 0 ? void 0 : _61.headerBackground) === null || _62 === void 0 ? void 0 : _62.color),
                on: (_64 = (_63 = style === null || style === void 0 ? void 0 : style.accordion) === null || _63 === void 0 ? void 0 : _63.headerBackground.on) !== null && _64 !== void 0 ? _64 : true,
            },
            bodyBackground: (_66 = (_65 = style === null || style === void 0 ? void 0 : style.accordion) === null || _65 === void 0 ? void 0 : _65.bodyBackground) !== null && _66 !== void 0 ? _66 : {
                on: false,
            },
            buttonAlignment: (_68 = (_67 = style === null || style === void 0 ? void 0 : style.accordion) === null || _67 === void 0 ? void 0 : _67.buttonAlignment) !== null && _68 !== void 0 ? _68 : BlockStyleTypes_1.AccordionWidgetButtonAlignment.Left,
            buttonStyle: (_70 = (_69 = style === null || style === void 0 ? void 0 : style.accordion) === null || _69 === void 0 ? void 0 : _69.buttonStyle) !== null && _70 !== void 0 ? _70 : BlockStyleTypes_1.AccordionWidgetButtonStyle.Arrow,
            buttonColor: (0, Utils_1.makeColor)(primary, (_71 = style === null || style === void 0 ? void 0 : style.accordion) === null || _71 === void 0 ? void 0 : _71.buttonColor),
            tint: {
                color: (0, Utils_1.makeColor)(defaultMidnight, (_73 = (_72 = style === null || style === void 0 ? void 0 : style.accordion) === null || _72 === void 0 ? void 0 : _72.tint) === null || _73 === void 0 ? void 0 : _73.color),
                opacity: (_76 = (_75 = (_74 = style === null || style === void 0 ? void 0 : style.accordion) === null || _74 === void 0 ? void 0 : _74.tint) === null || _75 === void 0 ? void 0 : _75.opacity) !== null && _76 !== void 0 ? _76 : 25,
            },
            cardStyle: (_78 = (_77 = style === null || style === void 0 ? void 0 : style.accordion) === null || _77 === void 0 ? void 0 : _77.cardStyle) !== null && _78 !== void 0 ? _78 : BlockStyleTypes_1.AccordionWidgetCardStyle.Simple,
            imageHeight: (_80 = (_79 = style === null || style === void 0 ? void 0 : style.accordion) === null || _79 === void 0 ? void 0 : _79.imageHeight) !== null && _80 !== void 0 ? _80 : BlockStyleTypes_1.AccordionWidgetImageHeight.Medium,
            expandButtonText: (_82 = (_81 = style === null || style === void 0 ? void 0 : style.accordion) === null || _81 === void 0 ? void 0 : _81.expandButtonText) !== null && _82 !== void 0 ? _82 : "Expand",
            closeButtonText: (_84 = (_83 = style === null || style === void 0 ? void 0 : style.accordion) === null || _83 === void 0 ? void 0 : _83.closeButtonText) !== null && _84 !== void 0 ? _84 : "Collapse",
        } }), (0, CommonUtils_1.includeIf)((style === null || style === void 0 ? void 0 : style.clonedFrom) !== undefined, { clonedFrom: style === null || style === void 0 ? void 0 : style.clonedFrom })), (0, CommonUtils_1.includeIf)(!!(style === null || style === void 0 ? void 0 : style.caption) && !(0, lodash_1.isEmpty)(style === null || style === void 0 ? void 0 : style.caption), {
        caption: style === null || style === void 0 ? void 0 : style.caption,
    }));
};
exports.oldSplashStylesToNewTextStyles = oldSplashStylesToNewTextStyles;
